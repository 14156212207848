<template>
  <section>
    <div class="card">
      <div class="card__front card__part">
        <i
          class="text-white transform scale-x-100 rotate-90 fas fa-sim-card fa-flip-horizontal card__front-square card__square text-28px"
        />

        <i
          :class="
            `text-white ${getCardIcon} card__front-logo card__logo text-28px`
          "
        />

        <p class="card_numer">**** **** **** {{ getEndingNumbers }}</p>
        <div class="card__space-75">
          <span class="card__label">{{
            $t(
              'components.riderDetailsManagement.diveIntoTab.paymentMethod.card.cardHolder'
            )
          }}</span>
          <p class="card__info">{{ name }}</p>
        </div>
        <div class="card__space-25">
          <span class="card__label">{{
            $t(
              'components.riderDetailsManagement.diveIntoTab.paymentMethod.card.expires'
            )
          }}</span>
          <p class="card__info">{{ getCardExpiry }}</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { getCreditCardIcon } from '@/utils'

export default {
  name: 'CreditCard',
  props: {
    name: {
      type: String,
      required: true,
      default: 'Farhan Israq',
    },
    number: {
      type: [String, Number],
      required: true,
    },
    expiry: {
      type: String,
      required: true,
    },
    brand: {
      type: String,
      required: false,
      default: 'OTHERS',
    },
  },
  computed: {
    getCardIcon() {
      return getCreditCardIcon(this.brand)
    },
    getEndingNumbers() {
      return this.number.toString().slice(-4) || '----'
    },
    getCardExpiry() {
      const expiry = this.$dayjs(this.expiry)
      if (expiry) {
        return `${expiry.format('MM')}/${expiry.format('YY')}`
      }
      return '--/--'
    },
  },
}
</script>

<style lang="scss" scoped>
.card {
  width: 100%;
  height: 190px;
  -webkit-perspective: 600px;
  -moz-perspective: 600px;
  perspective: 600px;
}

.card__part {
  box-shadow: 1px 1px #aaa3a3;
  top: 0;
  position: absolute;
  z-index: 1000;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 190px;
  /* background-image: linear-gradient(
    to right bottom,
    #fd696b,
    #fa616e,
    #f65871,
    #f15075,
    #ec4879
  ); */
  /*   linear-gradient(to right bottom, #fd8369, #fc7870, #f96e78, #f56581, #ee5d8a);*/
  // background-image: url('https://image.ibb.co/bVnMrc/g3095.png'),
  background-image: linear-gradient(
    to right bottom,
    #1f1e1e,
    #1a1919,
    #181717,
    #0c0c0c,
    #050505
  );
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 8px;

  -webkit-transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -moz-transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -ms-transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -o-transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}

.card__front {
  padding: 18px;
  -webkit-transform: rotateY(0);
  -moz-transform: rotateY(0);
  transform: rotateY(0);
}

// .card__back {
//   padding: 18px 0;
//   -webkit-transform: rotateY(-180deg);
//   -moz-transform: rotateY(-180deg);
//   transform: rotateY(-180deg);
// }

// .card__black-line {
//   margin-top: 5px;
//   height: 38px;
//   background-color: #303030;
// }

.card__logo {
  height: 16px;
}

.card__front-logo {
  position: absolute;
  top: 18px;
  right: 18px;
}
.card__square {
  border-radius: 5px;
  height: 30px;
}

.card_numer {
  display: block;
  width: 100%;
  word-spacing: 4px;
  font-size: 20px;
  letter-spacing: 2px;
  color: #fff;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 20px;
}

.card__space-75 {
  width: 75%;
  float: left;
}

.card__space-25 {
  width: 25%;
  float: left;
}

.card__label {
  font-size: 10px;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.8);
  letter-spacing: 1px;
}

.card__info {
  margin-bottom: 0;
  margin-top: 5px;
  font-size: 16px;
  line-height: 18px;
  color: #fff;
  letter-spacing: 1px;
  text-transform: uppercase;
}

// .card__back-content {
//   padding: 15px 15px 0;
// }
// .card__secret--last {
//   color: #303030;
//   text-align: right;
//   margin: 0;
//   font-size: 14px;
// }

// .card__secret {
//   padding: 5px 12px;
//   background-color: #fff;
//   position: relative;
// }

// .card__secret:before {
//   content: "";
//   position: absolute;
//   top: -3px;
//   left: -3px;
//   height: calc(100% + 6px);
//   width: calc(100% - 42px);
//   border-radius: 4px;
//   background: repeating-linear-gradient(
//     45deg,
//     #ededed,
//     #ededed 5px,
//     #f9f9f9 5px,
//     #f9f9f9 10px
//   );
// }

// .card__back-logo {
//   position: absolute;
//   bottom: 15px;
//   right: 15px;
// }

// .card__back-square {
//   position: absolute;
//   bottom: 15px;
//   left: 15px;
// }

// .card:hover .card__front {
//   -webkit-transform: rotateY(180deg);
//   -moz-transform: rotateY(180deg);
//   transform: rotateY(180deg);
// }

// .card:hover .card__back {
//   -webkit-transform: rotateY(0deg);
//   -moz-transform: rotateY(0deg);
//   transform: rotateY(0deg);
// }
</style>
